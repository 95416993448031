@import '../../assets/styles/variables';

.onboardingHeaderContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 24px 0;
  align-items: center;
  color: $Brand-Black;

  .onboardingTopHeader {
    font-size: 13px;
    font-weight: bold;

    @if isNewTheme() {
      display: none;
    }
  }

  .onboardingBottomHeader {
    @if isNewTheme() {
      font-family: getThemeValue('heading-font');
      font-size: getThemeValue('heading-font-size');
      font-color: getThemeValue('heading-font-color');
      font-weight: getThemeValue('heading-font-weight');
    } @else {
      font-size: 36px;
      line-height: 42px;
    }
  }
}

.deliveryStatusHeaderContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: $Padding-Default;
  box-shadow: getThemeValue('card-box-shadow');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('card-background-color');

  .topContainer {
    .deliveryStatusTopHeader {
      text-transform: getThemeValue('card-sub-heading-text-transform');
      font-size: getThemeValue('card-sub-heading-font-size');
      font-weight: getThemeValue('card-sub-heading-font-weight');
    }
  }

  .bottomContainer {
    line-height: 14px;
    padding: $Padding-Default;
    background-color: white;
  }
}

.requiredTasksHeading {
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-weight: 500;
  border-radius: 3px;
  line-height: 42px;
  text-align: left;
  padding: 0 $Padding-Default 0 $Padding-Default;
  margin: 10px 0 10px 0;

  @media (max-width: 785px) {
    border-radius: 0;
    font-size: 16px;
  }
}
.taskCardsContainer {
  display: flex;
  @media (max-width: 785px) {
    flex-direction: column;
  }
}

.appDownloadLink {
  padding-right: 12px;
  .appDownloadImage {
    padding-bottom: 3px;
  }
}

.appDownloadContent {
  padding: 25px 0 25px 0;
}

.appDownloadButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: $phone-width) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.taskCardContainer {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  background-color: getThemeValue('card-background-color');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('onboarding-task-border-radius');
  box-shadow: getThemeValue('card-box-shadow');
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 785px) {
    margin: 12px 0px;
  }

  .taskCard {
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 15px;
    border-radius: getThemeValue('container-border-radius');
    cursor: pointer;
    overflow: hidden;

    @media (max-width: 550px) {
      padding: 10px 5px 10px 5px;
    }

    @media (max-width: 330px) {
      padding: 7px 2px 7px 2px;
    }

    button {
      margin-top: 25px;
      margin-bottom: 10px;
    }
    .taskDescriptionContainer {
      align-items: center;
    }

    .taskIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin: 10px 20px 10px 10px;

      @media (max-width: 550px) {
        margin: 0 10px 0 0;
      }

      @media (max-width: 410px) {
        margin: 0 5px 0 0;
      }

      @media (max-width: 350px) {
        margin: 0 5px 0 0;
        height: 60px;
        width: 60px;
      }

      @media (max-width: 319px) {
        margin: 0 5px 0 0;
        height: 40px;
        width: 40px;
      }

      &.ongoingTaskIconContainer {
        background-color: $Gray-100;
        border-radius: 3px;
      }

      .taskIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 46px;
        color: $Gray-700;

        @media (max-width: 319px) {
          font-size: 30px;
        }

        &.completedTaskIcon {
          color: $Green-400;
          border-radius: 100%;
          font-size: 60px;
          height: 60px;
          width: 60px;

          @media (max-width: 613px) {
            font-size: 45px;
            height: 45px;
            width: 45px;
          }

          @media (max-width: 319px) {
            font-size: 40px;
            height: 40px;
            width: 40px;
          }
        }
      }
    }

    .taskDescriptionContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 6px;

      @media (max-width: 550px) {
        padding-top: 3px;
      }

      .taskHeading {
        color: getThemeValue('card-header-font-color');
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        width: 100%;

        @media (max-width: 480px) {
          font-size: 20px;
          line-height: 25px;
        }

        @media (max-width: 410px) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .taskMessage {
        text-align: center;
        margin: 0.75rem 0 -1rem;
      }

      .taskDescription {
        color: getThemeValue('card-header-font-color');
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        width: 100%;

        @media (max-width: 480px) {
          font-size: 20px;
          line-height: 25px;
        }

        @media (max-width: 410px) {
          font-size: 15px;
          line-height: 20px;
        }

        &.paymentTaskDescription:before {
          content: 'Upload proof of payment';

          @media (max-width: 740px) {
            content: 'Upload proof of payment';
          }
        }

        &.bankDetailsTaskDescription:before {
          content: 'Add a bank account';

          @media (max-width: 740px) {
            content: 'Add a bank account';
          }
        }

        &.ficaTaskDescription:before {
          content: 'Prepare your ID for delivery';

          @media (max-width: 740px) {
            content: 'Prepare FICA documents';
          }
        }

        &.digitalFICATaskDescription:before {
          content: 'Scan or upload your identity document';

          @media (max-width: 740px) {
            content: 'Scan or upload your identity document';
          }
        }

        &.posDownloadTaskDescription:before {
          content: 'Download the app';

          @media (max-width: 740px) {
            content: 'Download the app';
          }
        }

        &.onlineTaskDescription:before {
          content: 'Download the app';

          @media (max-width: 740px) {
            content: 'Download the app';
          }
        }

        &.datecsDownloadTaskDescription:before {
          content: 'Download the app';

          @media (max-width: 740px) {
            content: 'Download the app';
          }
        }

        &.transactionTaskDescription:before {
          content: 'Make your first card payment';

          @media (max-width: 740px) {
            content: 'Make first card payment';
          }
        }

        &.vettingInformationTaskDescription:before {
          content: 'Add business information';

          @media (max-width: 740px) {
            content: 'Add business information';
          }
        }

        &.unknownTaskDescription:before {
          content: 'Please try again later';
        }
      }

      .taskStepContainer {
        width: 100px;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
        text-transform: uppercase;
        padding: 4px 0 3px 0;
        margin: 10px 0 10px 0;

        @media (max-width: 550px) {
          margin: 5px 0 5px 0;
          font-size: 8px;
        }

        &.todoStep {
          color: $Blue-900;
          border-color: $Blue-300;
          background-color: $Blue-100;
        }

        &.inprogressStep {
          color: $Gray-900;
          border-color: $Gray-300;
          background-color: $Gray-200;
        }

        &.completeStep {
          color: $Green-900;
          border-color: $Green-300;
          background-color: $Green-100;
        }

        &.failedStep {
          color: $Red-900;
          border-color: $Red-300;
          background-color: $Red-100;
        }

        &.changeRequiredStep {
          color: $Yellow-900;
          border-color: $Yellow-300;
          background-color: $Yellow-100;
        }
      }
    }

    .taskExpansionIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 20px 30px 20px;
      background-color: #cbd1d9;
      border-radius: 3px;
      width: 60px;
      height: 60px;
      cursor: pointer;

      @media (max-width: 550px) {
        margin: 5px 0 0 5px;
      }

      @media (max-width: 435px) {
        margin: 5px 0 0 5px;
        height: 40px;
        width: 40px;
      }

      @media (max-width: 319px) {
        margin: 3px 0 0 3px;
        height: 30px;
        width: 30px;
      }

      .chevronDown::before {
        border: solid white;
        border-width: 0 0 3px 3px;
        content: '';
        display: inline-block;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: bottom;
        height: 15px;
        width: 15px;
        top: -5px;

        @media (max-width: 550px) {
          width: 12px;
          height: 12px;
        }
      }

      .chevronUp::before {
        border: solid white;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: bottom;
        height: 15px;
        width: 15px;

        @media (max-width: 550px) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .expandedContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top: 1px solid $light-gray;
    padding: 30px 30px 30px 120px;
    background-color: #ffffff;

    @media (max-width: 613px) {
      padding: 30px 20px 30px 90px;
    }

    @media (max-width: 570px) {
      padding: 30px 20px 30px 60px;
    }

    @media (max-width: 425px) {
      padding: 30px 20px 30px 30px;
    }
  }

  .expandedContainerNoPadding {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top: 1px solid $light-gray;
    padding: 0px 0px 30px 0px;
    background-color: rgba(207, 216, 220, 0.1);

    @media (max-width: 613px) {
      padding: 0px 0px 30px 0px;
    }

    @media (max-width: 570px) {
      padding: 0px 0px 30px 0px;
    }

    @media (max-width: 425px) {
      padding: 0px 0px 30px 0px;
    }
  }

  .taskCardRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: $phone-width) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .taskCardRowText {
      width: 60%;
      @media (max-width: $phone-width) {
        width: 100%;
      }
    }
  }

  .taskCardColumn {
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (max-width: $phone-width) {
      padding-top: 10px;
    }
  }

  .selectYourIdentity {
    color: $Brand-Black;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
  }

  .helperTextCopy {
    color: $font-new-dark;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 0 0 0;
    a {
      color: $yoco-blue;
      font-weight: bold;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .appDownloadGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 36px;
  }

  .appDownloadLink {
    margin: 4px 8px;
  }

  .bankingActionContainer {
    display: flex;
    align-items: center;

    @media (max-width: 570px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .bankingActionTitle {
    color: $font-new-dark;
    font-size: 16px;
    line-height: 24px;
  }

  .paymentActionHeading {
    display: flex;
    flex: 1;

    .ordersHeader {
      color: $font-new-dark;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      span {
        font-weight: bold;
      }
    }
    &.sameLineHeading {
      display: inline-flex;
    }

    @media (max-width: 570px) {
      font-size: 14px;
      line-height: 22px;
      padding-left: 5px;
      padding-bottom: 10px;
    }
  }

  .bankingDetailsHeader {
    color: $font-new-dark;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    padding-top: 10px;
  }

  .inlineActionDetailsContainer {
    display: flex;
    flex: 1;

    @media (max-width: 785px) {
      flex-direction: column;
    }

    @media (max-width: 570px) {
      flex-direction: column;
      padding: 0;
    }

    .paymentActionDataContainer {
      margin-bottom: 32px;

      @media (max-width: 785px) {
        width: 100%;
      }
    }
  }

  .posDownloadExpandedTaskContainer {
    display: flex;
    flex: 1;

    @media (max-width: 785px) {
      flex-direction: column;
    }
  }

  .inlineActionButtonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;

    &.appDownload {
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 785px) {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &.sameLineActionButton {
      display: inline-flex;
      float: right;

      @media (max-width: 785px) {
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
    }

    .inlineActionButton {
      background-color: $yoco-blue;
      border-radius: 3px;
      width: 240px;
      min-height: 50px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 50px;
      text-align: center;
      text-decoration: none;

      @media (max-width: 785px) {
        width: 100%;
      }

      @media (max-width: 240px) {
        width: 100%;
        font-size: 13px;
        min-height: 40px;
        line-height: 40px;
      }
    }

    .appDownloadLink {
      max-width: 180px;
      max-height: 60px;
    }
    .appDownloadImage {
      padding-bottom: 3px;
    }
  }

  .ficaBlockContainer {
    display: flex;
    flex-direction: row;
    width: 100%;

    .ficaInnerBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45%;
      padding: 20px;

      .ficaInnerImageBlock {
        display: grid;
        justify-content: center;
        padding-bottom: 16px;
        height: 100px;
        align-items: center;
        @media (max-width: 785px) {
          align-self: flex-start;
          align-items: flex-start;
        }
      }

      .ficaInnerContentBlock {
        .ficaInnerBlockTitle {
          color: $font-new-dark;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          text-align: center;
          padding: 16px 0 26px 0;

          p {
            @media (max-width: 785px) {
              text-align: left;
            }
          }

          @media (max-width: 785px) {
            padding: 0;
          }
        }

        .ficaInnerBlockDescription {
          color: $font-dark;
          font-size: 14px;
          line-height: 24px;
          p {
            text-align: center;
            @media (max-width: 785px) {
              text-align: left;
            }
          }
        }

        @media (max-width: 785px) {
          display: flex;
          flex-direction: column;
          margin-left: 30px;
          align-items: flex-start;
        }
      }

      @media (max-width: 785px) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    @media (max-width: 785px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .ficaStartTransactingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .startTransactingRow {
      display: flex;
      flex-direction: row;
      width: 80%;

      .transactingColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .rule {
        flex: 1;
        border: 1px dashed;
        color: $font-dashed-gray;
        margin: 30px 10px 0 10px;
      }
    }
    .textTransactingRow {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 40px 0 40px;

      @media (max-width: 1020px) {
        padding: 0 20px 0 20px;
      }
      @media (max-width: 900px) {
        padding: 0 10px 0 10px;
      }
      @media (max-width: 500px) {
        padding: 0;
      }

      .title {
        color: $font-new-dark;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;

        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.bottomCTA {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f1f3f4;
  margin: 0 -31px -30px -121px;
  display: flex;
  padding: 12px;

  @media (max-width: 766px) {
    margin: 0 -30px -30px -120px;
  }

  @media (max-width: 613px) {
    margin: 0 -20px -30px -90px;
  }

  @media (max-width: 570px) {
    margin: 0 -20px -30px -60px;
  }

  @media (max-width: 425px) {
    margin: 0 -20px -30px -31px;
  }

  p {
    color: #6a7987;
    text-align: center;
    margin: 0;

    @media (max-width: 785px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.separator {
  width: 100%;
  display: flex;
  height: 100px;
  margin: 41px 0 40px 0;

  @media (max-width: $menu-toggle-width) {
    height: 60px;
  }

  @media (max-width: 740px) {
    height: 30px;
  }

  .lineLeft {
    flex: 1;
    margin-left: 0;
    margin-top: 17px;
    margin-bottom: 17px;
    border: 1px solid $light-gray;
    height: 1px;

    @media (max-width: 785px) {
      margin-left: 20px;
    }
  }

  .lineRight {
    flex: 1;
    margin-right: 0;
    margin-top: 17px;
    margin-bottom: 17px;
    border: 1px solid $light-gray;
    height: 1px;

    @media (max-width: 785px) {
      margin-right: 20px;
    }
  }

  .lineOval {
    width: 36px;
    height: 36px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.setupProgress {
  margin-bottom: 50px;
}

.stepsContainer {
  font-size: 0;
  width: 100%;
  line-height: 2;
  display: inline-flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  height: 80px;

  @media (max-width: $phone-width) {
    flex-direction: column;
    display: block;
    height: initial;
  }

  &,
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.item {
  position: relative;
  vertical-align: top;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px 15px;
  margin-bottom: 60px;
  height: 80px;

  @media (max-width: $phone-width) {
    margin: 5px 12px;
  }

  .item {
    &:last-child {
      margin-right: 0;
      padding-right: 15px;
    }
  }

  &:last-child &-chevron {
    display: none;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    line-height: 28px;
    padding-right: 15px;

    .ycicon {
      font-size: 60px;
      position: relative;
      top: -2px;

      @media (max-width: $tablet-width) {
        font-size: 23px;
      }

      @media (max-width: $phone-width) {
        font-size: 45px;
      }
    }
  }

  &-chevron {
    position: absolute;
    border: 0 solid transparent;
    border-style: solid;
    border-color: transparent;
    border-width: 40px 20px;
    border-left-color: #e7eaeb;
    top: 0;
    bottom: 0;
    right: -40px;
    z-index: 2000;

    @media (max-width: $phone-width) {
      display: none;
    }
  }

  &-title {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 4px;
    display: inline-block;
    position: relative;
    font-weight: bold;

    @media (max-width: $tablet-width) {
      font-size: 15px;
    }
  }
  &-description {
    line-height: 1;
    font-size: 16px;
    font-weight: light;

    @media (max-width: $tablet-width) {
      font-size: 12px;
    }
  }
  &-wait {
    color: $font-dark;
  }
  &-finish {
    color: $off-white;
  }
  &-process {
    color: $font-dark;
  }

  &.item-wait {
    color: $font-dark;
  }

  &.item-error {
    i {
      color: $red;
    }
  }

  &-finish &-title {
    color: $off-white;
  }

  &-finish &-description {
    color: $off-white;
  }

  &:not(:first-child) &-icon {
    margin-left: 16px;
    @media (max-width: $phone-width) {
      margin-left: 0;
    }
  }
}

.inProgressHeader {
  width: 100%;

  .progressBlockContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0 10px 0;

    @media (max-width: 700px) {
      margin-left: 16px;
    }

    .progressBlockText {
      color: $font-new-dark;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.helperTextCopy {
  color: $font-new-dark;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: $screen-xs-max) {
    line-height: 18px;
  }

  a {
    color: $yoco-blue;
    font-weight: bold;
  }

  a:hover {
    text-decoration: none;
  }
}

.progressBar {
  max-width: 580px;
  max-height: 83px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  @media (max-width: 580px) {
    margin: 12px 0;
  }
}
