@import 'src/assets/styles/variables';

.documentTypeCardContainer {
  padding: 10px;
  cursor: pointer;

  &.documentTypeCard {
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 3px 2px #D3D6D8;
    border-radius: 2px;
  }

  @media (min-width: $screen-xxs-max) {
    width: 250px;
  }
}

.checkBoxButton {
  flex: 0.2;
}

.description {
  flex: 0.8;
  text-align: left;
}

.iconContainer {
  text-align: left;
}
