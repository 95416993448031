.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .image {
    width: 100px;
  }

  h1 {
    margin-top: 10px;
  }

  h4 {
    margin: 20px;
    font-size: 18px;
  }

  .button {
    margin-top: 20px;
  }
}
