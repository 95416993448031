@import 'src/assets/styles/variables';

.UploadIDDocumentTypeContainer {
  padding-bottom: 15px;
}
.documentTypeHeaderText {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.documentTypeCardsContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-xxs-max) {
    flex-direction: row;
  }
}
