@import './src/assets/styles/variables.scss';

.iidentifiiForm {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: auto;
  min-height: 240px;
  justify-content: center;
  padding: 24px 0;
}

.iidentifiiField {
  padding: 12px 0;
}

.iidentifiiField input {
  height: 48px;
  border-radius: 0.5rem;
}

.iidentifiiButton {
  width: 100%;
  height: 48px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 $gray-background;
  font-size: 16px;
  margin-top: 24px;
}

.iidentifiiButton:hover {
  box-shadow: 0 4px 8px 0 $darker-border;
  transition: 0.2s;
}