@import "../../assets/styles/variables.scss";

.filters {
  position: relative;
  background: getThemeValue('filters-background-color');

  @media (max-width: $menu-toggle-width) {
    display: none;
  }

  &.search {
    background: white;
    height: 50px;

    @media (max-width: $menu-toggle-width) {
      height: 47px;
      padding-left: 0;
    }
  }

  &.mobileInline {
    padding-bottom: 12px;
  }

  &.mobile {
    display: none;
    position: relative;

    &.search {
      background: white;

      &.blueBackground {
        width: 100%;
        height: 70px;
        background: #00a2e0;
        padding-top: 2px;
      }
    }

    .filterHeader {
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 24px;
      cursor: pointer;
    }

    .filtersContainer {
      display: none;
    }

    @media (max-width: $menu-toggle-width) {
      display: block;
    }

    &.shown {
      .filtersContainer {
        display: block;
        position: absolute;
        left: -1 * getThemeValue('content-sm-padding-size');
        right: -1 * getThemeValue('content-sm-padding-size');
        padding: getThemeValue('content-sm-padding-size');
        z-index: 5000;
        background: getThemeValue('content-background-color');
        box-shadow: getThemeValue('card-box-shadow');
      }

      .filterHeader {
        .dropdownArrow {
          -webkit-transform: translate(0, -4px) rotate(180deg);
        }
      }
    }

    .filtersContainer .filtersRow {
      display: block;

      .filter {
        margin-bottom: $Padding-Tighter;
      }
    }
  }

  .iconSpan {
    margin-left: 16px;
    margin-right: 34px;
    line-height: 47px;

    @media (max-width: $menu-toggle-width) {
      display: block;
      margin-bottom: 4px;
      cursor: pointer;
    }

    i {
      margin-right: 28px;
      font-size: 18px;

      &:before {
        height: 100%;
        vertical-align: middle;
      }
    }

    .endIcon {
      position: absolute;
      right: 31px;
      font-size: 16px;
    }
  }

  .filtersContainer {
    padding: 0;

    .filtersRow {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
    }

    &.blueBackground {
      width: 100%;
      background: $yoco-blue;
    }
  }
}

.dateHeader {
  .filter {
    .selected {
      border: 1px solid getThemeValue('filter-border-color');
      .clickableContainer {
        a, i {
          position: relative;
          bottom: 2px;
        }
      }
    }

    &.toggledFilter {
      .toggled {
        border: 1px solid getThemeValue('filter-border-color');
        overflow: hidden;
      }

      .clickableContainer {
        background: white;
        z-index: 1051;
      }
    }
  }

  @media(max-width: $menu-toggle-width) {
    .filter {
      &.toggledFilter {
        .clickableContainer {
          z-index: 1049;
        }
      }
    }
  }
}

.filter {
  min-width: 230px;

  @media (max-width: $tablet-width) {
    min-width: 150px;
  }

  button.filterButton {
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
  }

  &.dateFilter {
    margin-right: 0;
    
    .dropdown {
      right: 0px;
      left: inherit;

      @media (min-width: $screen-xxl-min) and (max-width: $screen-xxl-max) {
        right: inherit;
        left: 0px;
      }

      @media (min-width: 1020px) and (max-width: 1026px) {
        right: inherit;
        left: 0px;
      }
    }

    @media (max-width: $menu-toggle-width) {
      position: relative;
      width: 100%;
    }
  }

  &.noPosition {
    position: initial;

    .dropdown {
      right: inherit;
      left: 0;
    }
  }

  .dropdown {
    @media (max-width: $menu-toggle-width) {
      padding-bottom: 100px;
    }
    
    &.dateFilter {
      width: 524px;
      padding-top: 12px;

      .responsiveTitle {
        margin: 0 22px;
        display: none;
      }

      .customDivider {
        position: relative;
        margin-top: 22px;

        .line {
          border-bottom: 1px solid #efefef;
        }

        .text {
          display: inline-block;
          position: absolute;
          top: -9px;
          margin-left: 12px;
          background: white;
          padding: 0 5px;
        }
      }

      .dateDisplay {
        margin: 22px;
        position: relative;

        :global {
          .icon-arrow-right {
            margin: 0px 12px;
            color: $yoco-blue;
            font-size: 24px;

            &:before {
              vertical-align: middle;
            }
          }
        }

        .dates {
          text-align: left;

          .descriptor {
            text-align: left;
          }

          .faded {
            color: $font-light-gray;
          }

          .dateEndBlock {
            display: inline-block;

            .number-select {
              margin: 0;
            }
          }
        }

        .actionsBlock {
          margin-top: 16px;
          text-align: right;

          .flatButton {
            margin-right: 8px;
          }
        }
      }

      .lineItem {
        .option {
          border-bottom: none;
          display: inline-block;
          line-height: 28px;
          width: 180px;

          .optionButton {
            position: relative;
            margin-right: 12px;
          }
        }

        .numberSelect {
          display: inline-block;

          &.monthSelect {
            overflow: visible;

            &.toggled {
              position: relative;

              .valueDisplay {
                border-bottom: none;
              }

              .monthDropdown {
                position: absolute;
                max-height: 228px;
                overflow-y: auto;
                min-width: 100%;
                background: white;
                border: 1px solid getThemeValue('filter-border-color');

                .dropdownOption {
                  line-height: 38px;
                  padding: 0 12px;

                  &:hover {
                    background: $Green-50;
                  }

                  &.pickedOption {
                    background: $Green-500;
                    color: white;
                  }

                  .optionLabel {
                    display: block;
                    margin: 0 12px;
                    line-height: 38px;
                    border-bottom: 1px solid #efefef;
                  }
                }
              }
            }
          }
        }
      }

      @media (max-width: $menu-toggle-width) {
        width: 100%;

        .responsiveTitle {
          display: block;
        }

        .lineItem {
          .option {
            margin-left: 22px;
            margin-right: 22px;
          }

          .numberSelect {
            margin-left: 22px;
          }
        }

        .dateDisplay {
          .dates {
            .dateEndBlock {
              margin-bottom: 16px;
            }

            .icon-arrow-right {
              display: none;
            }
          }
        }
      }
    }
  }
}

.dateHeader {
  header {
    .filter {
      &.dateFilter {
        .selected {
          .dropdown {
            right: inherit;
            left: 0px;
          }
        }
      }
    }
  }
}

// This provides some additional styling to the date picker
.pickerContainer {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 10;

  :global {
    .react-calendar {
      font-family: getThemeValue('font');
      border: getThemeValue('form-field-border');
    }
   
    .react-calendar__tile--now {
      background: $Green-50;
    }

    .react-calendar__tile:enabled:hover {
      background: $Green-400;
      color: white;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus,
    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus,
    .react-calendar__tile--hasActive,
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar--selectRange .react-calendar__tile--active,
    .react-calendar--selectRange .react-calendar__tile--hover {
      background: $Green-500;
      color: white;
    }
  }
}

.filter {
  position: relative;
  display: inline-block;
  padding: 0 !important;
  height: 47px;

  border-width: getThemeValue('filter-border-width');
  border-color: getThemeValue('filter-border-color');
  border-style: solid;
  background: getThemeValue('filter-background-color');
  border-radius: getThemeValue('filter-border-radius');
  font-size: getThemeValue('filter-font-size');
  color: getThemeValue('filter-font-color');
  margin: 0 4px 4px 0;

  .devicePickerContainer {
    display: flex;
    height: 304px;
    overflow: scroll;
    flex-direction: column;
  }
  
  .optionDevice {
    &:last-child {
      border-bottom: unset;
    }
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
    margin: 0 24px;
    border-bottom: 1px solid #efefef;
  }
  
  .deviceInformation {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  
  .deviceOptionFooter {
    display: flex;
    gap: 1em;
  }  

  @media (max-width: $menu-toggle-width) {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .selectedContainer {
    overflow: hidden;
    display: block;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selected {
    overflow: hidden;

    height: 47px;

    cursor: pointer;

    &.toggled {
      overflow: initial;

      .clickableContainer {
        z-index: 11;
        background: white;

        i, a {
          position: relative;
        }
      }
    }

    .clickableContainer {
      display: block;
      line-height: 47px;
      height: 47px;
      position: relative;
      top: 0px;
      width: 100%;

      .leftFloat {
        float: left;
        margin-left: 12px;

        &:before {
          font-size: 20px;
        }
      }

      :global {
        .icon-arrow-65 {
          transition: 200ms;
        }
      }

      i {
        margin-left: 6px;
        margin-right: 12px;
        float: right;

        &:before {
          line-height: 47px;
        }

        // @media (max-width: @screen-md-min) {
        //   position: absolute;
        //   right: 24px;
        // }
      }
    }

  }

  .toggled {
    background: white;
    z-index: 5;

    .clickableContainer {
      :global {
        .icon-arrow-65 {
          transform: translate(0, -4px) rotate(180deg);
        }
      }
    }

    a {
      color: #29A7DD;
    }

    &::after {
      visibility: hidden;
    }
  }

  .dropdown {
    z-index: 1100;
    width: 450px;
    top: 45px;
    left: 0px;
    position: absolute;
    background: white;
    border: 1px solid getThemeValue('filter-border-color');
    
    @media (max-width: $menu-toggle-width) {
      padding-bottom: 100px;
    }

    &.leftDropdown {
      right: 0px;
      left: inherit;
    }

    .doneButton {
      display: none;
      color: #29A7DD;
      font-size: 18px;
      position: absolute;
      right: 0px;
      height: 35px;
      bottom: 0px;
      left: 0px;
      padding-right: 24px;
      text-align: right;

      @if isNewTheme() {
      } @else {
      }
    }

    .doneAndResetButtonsContainer {
      display: flex;
      justify-content: space-between;
    }

    .closeButton {
      display: none;
      text-align: right;
      margin-top: 8px;

      i {
        font-size: 20px;
        color: $font-dark;
      }
    }

    @media (max-width: $menu-toggle-width) {
      bottom: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: fixed;
      overflow-y: auto;

      .closeButton {
        position: initial;
        display: block;
        margin-right: 16px;
        margin-top: 16px;
      }

      .optionsFooter {
        position: initial;
      }

      .doneButton {
        position: initial;
        display: block;
      }
    }

    .searchBox {
      border-bottom: 1px solid #E5E5E5;

      input {
        border: none;
        height: 54px;
        box-shadow: none;
        padding: 12px 18px;
      }
    }

    .optionsContainer {
      padding-top: 12px;
      padding-bottom: 12px;

      max-height: 630px;
      overflow-y: auto;
      overflow-x: hidden;

      .loadMore {
        text-align: center;
        padding: $Padding-Default;
        font-size: getThemeValue('font-size');
      }
    }

    .option {
      position: relative;
      padding: 12px 0px;
      border-bottom: 1px solid #E5E5E5;
      margin-left: 24px;
      margin-right: 24px;

      i {
        margin-right: 12px;
        margin-top: 4px;
        vertical-align: top;
        font-size: 24px;
      }

      .textBlock {
        display: inline-block;
      }

      .title {
        display: block;
        font-size: 16px;
        color: #1E1E1E;
      }

      .subtext {
        display: block;
        font-size: 14px;
        color: #757575;
      }

      .optionButton {
        display: inline-block;
        vertical-align: top;
        position: absolute;
        right: 0px;
        border-radius: 18px;

        width: 28px;
        height: 28px;
        border: 1px solid getThemeValue('form-control-inactive-border-color');
        background: white;

        button {
          width: 18px;
          height: 18px;
          padding: 0px;
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 9px;
          border: none;
          background: white;

          &:active {
            background: getThemeValue('form-control-active-color');
            -ms-transform: scale(1.1,1.1); /* IE 9 */
            -webkit-transform: scale(1.1,1.1); /* Safari */
            transform: scale(1.1,1.1);
          }
        }
      }

      .selectedOptionButton {
        border: 1px solid getThemeValue('form-control-active-border-color');

        button {
          background: getThemeValue('form-control-active-color');
        }
      }

      &:hover {
        .optionButton {
          border: 1px solid getThemeValue('form-control-active-color');
        }

        .selectedOptionButton {
          button {
            opacity: 0.6;
            background: getThemeValue('form-control-active-color');
          }
        }
      }
    }

    .lastOption {
      border-bottom: none;
    }


    .optionsFooter {
      border-top: 1px solid #E5E5E5;
      display: flex;
      flex-direction: row;
      display: -ms-flexbox;
      -ms-flex-direction: row;
      display: -webkit-flex;
      -webkit-flex-direction: row;

      @media (max-width: $menu-toggle-width) {
        background-color: white;
        display: block;
      }

      .textBlock {
        line-height: 28px;

        .title {
          color: #757575;
        }
      }

      .multipleSelect {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;
        border-bottom: none;
        margin: 0;
        padding: 12px 22px 12px 22px;
        display: flex;
        flex-direction: row;
        display: -ms-flexbox;
        -ms-flex-direction: row;
        display: -webkit-flex;
        -webkit-flex-direction: row;

        .textBlock {
          flex: 1;
          -ms-flex: 1;
          -webkit-flex: 1;
        }

        .inlineDiv {
          display: inline-block;
          margin-top: 1px;
          height: 24px;
        }
      }

      .emptyOption {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;
        border-bottom: none;
        margin: 0;
        padding: 12px 6px 12px 22px;
        border-right: 1px solid #E5E5E5;

        .optionButton {
          position: relative;
          margin-right: 12px;
        }
      }
    }
  }
}

.filterToggle {
  display: inline-block;
  padding: 8px 12px;
  position: relative;
  background: #efefef;
  height: 47px;

  @media(max-width: $tablet-width) {
    width: 100%;
  }

  .textBlock {
    display: inline-block;
    padding-right: 12px;
    vertical-align: -webkit-baseline-middle;

    @media(max-width: $tablet-width) {
      display: none;
    }

    @media(min-width: $phone-width) {
      display: inline-block;
    }
  }

  .toggle {
    float: right;

    :global {
      .onoffswitch-label {
        border: 1px solid #FFFFFF;
      }
    }
  }
}

.toggleButton {
  border-radius: 18px;
  width: 28px;
  height: 28px;
  border: 1px solid #979797;
  background: white;
  position: relative;

  &:hover {
    border: 1px solid #29A7DD;
  }

  button {
    width: 18px;
    height: 18px;
    padding: 0px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 9px;
    border: none;
    background: white;

    &:active {
      background: #29A7DD;
      -ms-transform: scale(1.1,1.1); /* IE 9 */
      -webkit-transform: scale(1.1,1.1); /* Safari */
      transform: scale(1.1,1.1);
    }
  }

  &.toggledToggleButton {
    border: 1px solid #29A7DD;

    button {
      background: #29A7DD;
    }

    &:hover {
      button {
        opacity: 0.6;
        background: #29A7DD;
      }
    }
  }
}

.numberSelect {
  @if isNewTheme() {
    border: getThemeValue('form-field-border');
    border-radius: getThemeValue('form-field-border-radius');
  } @else {
    background: $gray-background;
  }
  display: inline-block;
  margin: 0px 12px;

  &.dateSelect {
    position: relative;

    i {
      padding: 0px 12px;
      font-size: 14px;
      border-left: solid 1px #a7bac4;
      margin: 0;
      color: inherit;

      &:before {
        vertical-align: middle;
      }
    }
  }

  .button {
    display: inline-block;
    padding: 0px 12px;

    &.borderLeft {
      border-left: 1px solid $darker-border;
    }

    &.borderRight {
      border-right: 1px solid $darker-border;
    }
  }

  .valueDisplay {
    display: inline-block;
    @if not isNewTheme() {
      border-bottom: 1px solid $yoco-blue;
    }
    width: 48px;
    padding: 6px 0px;
    text-align: center;
    margin: 0 4px;

    input {
      width: 46px;
      background: none;
      border: none;
      text-align: center;
    }

    &.dateValueDisplay {
      margin: 0px 12px;
      padding: 6px 0px;
      text-align: left;
      width: 150px;
    }
  }

  i {
    padding: 0px 12px;
    font-size: 14px;
    border-left: solid 1px $darker-border;
  }

}
