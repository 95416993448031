
@import 'assets/styles/variables';

.bankStatementMessageContainer{
    text-align: left;
}

.bankStatementErrorMsg{
    background: getThemeValue('alert-danger-background-color');
    border: 1px solid getThemeValue('insight-negative-color');; 
    border-radius: 2px;
    padding: getThemeValue('alert-padding');
    font-size: getThemeValue('flagship-error-card-font-size');
    line-height: 15px;
    color: $Brand-Black;
    font-family: getThemeValue('font');
    margin-bottom: 12px;

    strong{
        font-weight: 700;
    }
}

.bankStatementMessageHeading{
    font-weight: 600;
    font-size: getThemeValue('flagship-error-card-button-font-size');
    line-height: 22px;
}

.bankStatementMessageInstructions{
    background: getThemeValue('flagship-error-card-background');
    border-radius: 2px;
    padding: getThemeValue('content-sm-padding-size') getThemeValue('alert-padding');
    
    &.borderRound{
      border-radius: 5px;
      display: none;
    }

    h2{
        font-weight: 500;
        font-size: getThemeValue('flagship-error-card-sub-heading-font-size');
        line-height: 19px; 
        color: $Brand-Black;
        margin: 0 0 8px 0;
    }

    ol {
        padding-left: getThemeValue('alert-padding');
        margin: 0;
        list-style: none; counter-reset: counter;

        li {
            font-size: getThemeValu('flagship-error-card-sub-heading-font-size');
            line-height: 15px;
            color: $Brand-Black;
            font-family: getThemeValue('font');
            margin-bottom: 13px;
            text-align: left;
            counter-increment: counter;

            &::before {
                content: counter(counter)".";
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
                font-weight: 600;
                font-size: getThemeValu('flagship-error-card-sub-heading-font-size');
                line-height: 17px;
                color: getThemeValue('font-color');
            }

            &:last-of-type{
                margin:0;
            }

            strong {
                color: $Green-500;
            }
        }
    }
}