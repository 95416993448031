
.subText {
  font-size: 20px;
  font-weight: 600;
  padding: 25px;
  margin-bottom: 0px;
}

.contentText {
  font-size: 15px;
  padding: 10px;
}
