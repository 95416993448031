@import '../../../assets/styles/variables';

.container {
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;

  @media (max-width: $menu-toggle-width) {
    background-color: white;
    width: 100vw;
    position: fixed;
  }

  .body {
    text-align: center;
    width: 90%;

    @media (max-width: $menu-toggle-width) {
      width: 100%;
    }
  }

  .contentContainer {
    padding: 2em;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 8px;
    @media (max-width: $menu-toggle-width) {
      border-radius: unset;
    }
  }

  .image {
    width: 5em;
    height: 5em;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .button {
    min-width: 175px;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
