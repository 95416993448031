@import '../../../../assets/styles/variables';

.inlineActionButtonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;

  &.appDownload {
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 785px) {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &.sameLineActionButton {
    display: inline-flex;
    float: right;

    @media (max-width: 785px) {
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .inlineActionButton {
    background-color: $yoco-blue;
    border-radius: 3px;
    width: 250px;
    min-height: 50px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    margin: 20px;
    padding: 0 10px;
    border: 0;

    @media (max-width: 480px) {
      width: 100%;
    }

    @media (max-width: 240px) {
      width: 100%;
      font-size: 13px;
      min-height: 40px;
      line-height: 40px;
    }
  }

  .inlineActionButton:disabled {
    background-color: rgba(239, 239, 239, 0.9);
    border-radius: 3px;
    width: 250px;
    min-height: 50px;
    color: #CFD8DC;
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    margin: 20px;
    padding: 0 10px;
    border: 0;
    align-self: center;
    cursor: not-allowed;

    @media (max-width: 480px) {
      width: 100%;
    }

    @media (max-width: 240px) {
      width: 100%;
      font-size: 13px;
      min-height: 40px;
      line-height: 40px;
    }
  }

  .appDownloadLink {
    margin: 5px;
    padding: 5px;
    width: 180px;
    height: 60px;
  }
}

.header {
  width: 100%;
  font-size: larger;
  padding-top: 10px;

  @media (max-width: $screen-xs-max) {
    padding: 16px;
    padding-left: 30px;
  }

  @media (max-width: $screen-xxs-max) {
    padding: 16px;
  }

  .helperTextCopy {
    color: $font-new-dark;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: $screen-xs-max) {
      line-height: 18px;
    }

    a {
      color: $yoco-blue;
      font-weight: bold;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .mainContainer {
    display: flex;
    flex-direction: row;
  }
}

.inlineActionButton {
  align-self: center;
  padding-right: 30px;

  @media (max-width: 613px) {
    padding-right: 20px;
  }

  @media (max-width: 570px) {
    padding-right: 20px;
  }

  @media (max-width: 425px) {
    padding-right: 20px;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}



