@import 'assets/styles/variables';

.flagshipBankDetailsSuccessContainer{
    width: 100%;
    height: calc(100vh - 32px);
    display: grid;
    place-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 32px;
    position: relative;

    .successIconContainer{
        width:80px;
        height:80px;
        margin: 0 auto;

        img{
            width:100%;
            height:auto;
        }
    }

    .flagshipBankDetailsSuccessHeading{
        font-family: getThemeValue('title-font');
        font-weight: normal;
        font-size: getThemeValue('flagship-success-heading-font-size');
        line-height: 28px;
        text-align: center;
        color: $Brand-Black;
        margin: 16px 0;
    }

    .flagshipBankDetailsBody{
        font-family: getThemeValue('font');
        font-weight: normal;
        font-size: getThemeValue('alert-font-size');
        line-height: 19px;
        text-align: center;
        color: $Brand-Black;
    }

    .footerButtonContainer{
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0;

        button{
            width: 100%;
        }
    }
}