
.documentRowContainer {
  display: flex;
  flex-direction: row;
  paddingBottom: 10px;
}

.rowIconContainer {
  padding-right: 10px;
  padding-bottom: 10px;
}

.rowDescriptionContainer {
  padding: 3px;
}

.rowDescription {
  font-size: 16px;
  text-align: left;
  margin: 0px;
}
