.noteWrapper {
  padding-top: 10px;
}

.noteContainer {
  text-align: left;
  background-color: #F8CF69;
  padding: 10px;
  border-radius: 3px;
}

.note {
  font-weight: 700;
}
