@import '../../../../../assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 60px 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.heading {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  h1 {
    margin: 0;
  }
  img {
    margin: 24px 0;
  }
}

.tasksHeading {
  margin-bottom: 20px;
  p {
    margin: 0;
  }
}

.tasks {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -22px;

  & > * {
    margin-left: 22px;
  }
}

.task {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0px;
  padding: 22px;
  margin-bottom: 22px;
  background: getThemeValue('card-background-color');
  box-shadow: 0px 0.756881px 1.51376px rgba(0, 0, 0, 0.06), 0px 0.756881px 2.27064px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  h2, h3, h4, h5, h6 {
    margin: 16px 0;
    font-family: getThemeValue('subtitle-font');
    font-size: 18px;
    font-weight: getThemeValue('subtitle-font-weight');
    color: getThemeValue('subtitle-font-color');
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  i {
    display: block;
    width: 96px;
    height: 96px;
    line-height: 108px;
    text-align: center;
    border-radius: 8px;
    background: $Gray-100;
    color: $Gray-700;
  }

  span {
    display: block;
    width: 96px;
    background: $Blue-100;
    color: $Blue-700;
    text-align: center;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 2px 6px;
    margin-top: 4px;
  }
}

.taskDone {
  span {
    background: $Green-100;
    color: $Green-700;
  }
}