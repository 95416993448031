@import './src/assets/styles/variables.scss';

.buttonGroup {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: auto;
  min-height: 240px;
  justify-content: center;
}

.iconButton {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  padding: 20px;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid $gray-background;
  box-shadow: 0 1px 2px 0 $gray-background;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
}

.iconButton:hover {
  box-shadow: 0 4px 8px 0 $gray-background;
}

.icon {
  padding-right: 16px;
}

.faceIcon {
  padding-top: 16px;
  width: 96px;
  margin: auto;
}