@import "../../assets/styles/variables.scss";

.container {
  display: inline-flex;
  flex: auto;
  padding-top: 10px;

  .copyToClipboardIcon {
    display: inline-flex;
    position: relative;
    font-size: 20px;
    cursor: pointer;
    color: $yoco-blue;
    width: 21px;
    height: 24px;

    &.rightIcon {
      margin-left: 10px;

      @media(max-width: 420px) {
        margin-left: 5px;
      }
    }

    &.leftIcon {
      margin-right: 16px;

      @media(max-width: 420px) {
        margin-left: 5px;
      }
    }
  }

  .labelContainer {
    display: inline-flex;
    flex: 1;
    border-bottom: 1px solid $light-gray;
    color: #212B36;
    font-size: 16px;
    line-height: 10px;

    .labelClass {
      width: 100%;
      padding: 3px 0;

      @media (max-width: 410px) {
        font-size: 14px;
      }
    }
  }

  .toolTip {
    right: 0;
    bottom: 0px;
    float: right;
    position: fixed;
    visibility: visible;

    .toolTipText {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      z-index: 1;
    }
  }
}
