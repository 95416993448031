@import 'assets/styles/variables';

.documentTitle {
  color: #637381;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.documentSubtext {
  color: #637381;
  font-size: 12px;
  text-align: left;
}

.flagshipDocumentTitle{
  font-family: getThemeValue('title-font');
  font-size: getThemeValue('flagship-error-card-button-font-size');
  line-height: 21px;
  color: $Brand-Black;
  text-align: left;
  font-weight: unset;
}

.flagshipDocumentSubtext{
  color: $Brand-Black;
  text-align: left;
  font-weight: 500;
  font-size: getThemeValue('flagship-error-card-sub-heading-font-size');
  line-height: 17px;
}