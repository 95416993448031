@import './src/assets/styles/variables.scss';

.infoLabel {
  color: $font-light-gray;
  text-align: center;
  margin-bottom: 0 !important;
}

.infoValue {
  text-align: center;
  font-weight: 600;
  color: $font-dark;
}

.iidentifiiInfo {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: auto;
  min-height: 20px;
  justify-content: center;
  padding: 24px 0;
}

.iidentifiiField {
  padding: 12px 0;
}

.iidentifiiField input {
  height: 48px;
  border-radius: 0.5rem;
}