@import "../../../assets/styles/variables.scss";

.cardContentsContainer {
  padding: 0 20px;
  margin-bottom: 20px;
  @media (max-width: $phone-width) {
    width: 100%;
    padding: 0 20px;
  }

  h4 {
    font-weight: bold;
  }
}

.continueButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 3px;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
  }
}

.reAdvanceSliderContainer {
  padding: 0px 32px;
}
