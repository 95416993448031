@import 'assets/styles/variables';

.bankingDetailsErrorContainer{
    text-align: left;
}

.bankingDetailsErrorMsg{
    background: getThemeValue('alert-danger-background-color');
    border: 1px solid getThemeValue('insight-negative-color');; 
    border-radius: 2px;
    padding: getThemeValue('alert-padding');
    font-size: getThemeValue('flagship-error-card-font-size');
    line-height: 15px;
    color: $Brand-Black;
    font-family: getThemeValue('font');

    strong{
        font-weight: 700;
    }
}

.bankingDetailsErrorSubHeading{
    font-weight: 600;
    font-size: getThemeValue('alert-font-size');
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $Brand-Black;
    margin: getThemeValue('alert-margin-bottom') 0 8px 0;
}

.bankingDetailsErrorInstructions{
    background: getThemeValue('flagship-error-card-background');
    border-radius: 2px;
    padding: getThemeValue('content-sm-padding-size') getThemeValue('alert-padding');
    margin-bottom: 12px;
    
    &.borderRound{
      border-radius: 5px;
      display: none;
    }

    h2{
        font-weight: 500;
        font-size: getThemeValue('flagship-error-card-sub-heading-font-size');
        line-height: 19px; 
        color: $Brand-Black;
        margin: 0 0 8px 0;
    }

    ol {
        padding-left: getThemeValue('alert-padding');
        margin: 0;

        li {
            font-size: getThemeValue('flagship-error-card-font-size');
            line-height: 15px;
            color: $Brand-Black;
            font-family: getThemeValue('font');
            margin-bottom: 13px;

            &:last-of-type{
                margin:0;
            }

            strong {
                color: $Green-500;
            }
        }
    }
}

.flexContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: getThemeValue('flagship-error-card-font-size');

    &>div{
        padding-bottom: 0;
    }

    form {
       input {
           width: 157px;
       }

        button {
            margin-bottom: 5px;
            position: absolute;
            bottom: 0;
            left: 168px;
        }
    }

    input{
        height: 52px;
    }

    button {
        height: 52px;
        width: 135px ;
        margin-left: 4px;
        font-size: getThemeValue('flagship-error-card-button-font-size');
        border-radius: 6px;
        flex-shrink: 0;
    }
}
