
.uploadIDTextContainer {
  text-align: left;

  &.uploadIDText {
    font-size: 16px;
    font-weight: 700;
  }
}

.uploadIDNoteContainer {
  text-align: left;

  &.uploadIDNote {
    font-size: 12px;
    color: #637381;
  }
}
