
.headerText {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.headerSubtext {
  text-align: left;
  font-size: 16px;
}
