@import '../../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: getThemeValue('card-box-shadow');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');
  margin-bottom: 12px;

  @media (max-width: $phone-width) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: $screen-md-min) {
    margin: 0 10px;
  }

  .reApplyButton {
    height: 48px;
    min-width: 165px;
    background-color: $yoco-blue;
    border-radius: 3px;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: $phone-width) {
      margin-top: 12px;
    }
  }
}
