.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1em;

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;

    color: #222222;
  }

  .body {
    width: 50%;
    text-align: center;
  }

  .button {
    padding: 1.5em;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .image {
    width: 6em;
    height: 6em;
  }
}
